// variants
export const PAGES_VARIANT = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};

export const SECTION_VARIANT = {
  hidden: {
    opacity: 0,
    y: "10px",
  },
  visible: {
    opacity: 1,
    y: 0,
  },
};
